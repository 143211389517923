<template>
  <div>
    <c-search-box>
      <template slot="search">
        <div class="col-xs-12 col-sm-12 col-md-8 col-lg-8 col-xl-8">
          <!--목표년도-->
          <c-datepicker
            :editable="editable"
            type="year"
            label="목표년도"
            name="targetYear"
            v-model="searchParam.targetYear"
          />
        </div>
        <div class="col-xs-12 col-sm-12 col-md-4 col-lg-4 col-xl-4" style="padding-top: 20px !important;">
          <c-btn label="복사" icon="check" @btnClicked="select" />
        </div>
      </template>
    </c-search-box>
  </div>
</template>

<script>
export default {
  name: 'safety-healthy-mst-popup',
  props: {
  },
  data() {
    return {
      searchParam: {
        plantCd: null,
        targetYear: '',
        useFlag: 'Y',
        deptCd: '',
      },
      editable: true,
    };
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
  },
  computed: {
  },
  watch: {
  },
  methods: {
    init() {
    },
    select() {
      let selectData = this.searchParam.targetYear;
      if (!selectData) {
        window.getApp.$emit('ALERT', {
          title: '안내',
          message: '복사대상 년도를 선택하세요.',  // 년도별 목표를 선택하세요.
          type: 'warning', // success / info / warning / error
        });
      } else {
        this.$emit('closePopup', {year: selectData});
      }
    },
  }
};
</script>